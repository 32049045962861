@import '../../Styles/variables.scss';

.programme-view {
  padding: 0px 50px;

  .ant-steps-item-tail::after {
    background-color: #ffff !important;
    background-color: #ffff !important;
    border-left: 1px dashed #d9d9d9;
    border-style: dashed;
    border-width: 1px;
    border-color: #d9d9d9;
  }

  .action-btns {
    justify-content: center !important;
    button {
      margin-right: 10px;
      margin-left: 10px;
    }
    button:last-child {
      margin-right: 0px;
    }
  }

  .action-btns-card {
    justify-content: center !important;
    button {
      margin-right: 2px;
      margin-left: 2px;
    }
    button:last-child {
      margin-right: 0px;
    }
  }

  .action-button-container{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 5px;

    button{
      margin-left: 0px;
      margin-right: 0px;

      span{
        line-height: 1.7;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media (min-width: 768px) and (max-width: 1772px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .action-button-container-single{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }



  .title-bar {
    display: flex;
    justify-content: space-between;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: 13px;
  }

  .apexcharts-legend-series {
    padding-bottom: 5px;
  }

  .card-container {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;

    .action-btns {
      margin-top: 25px;
      margin-bottom: -15px;
      justify-content: end;
    }
  }

  .programme-logo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: $dev-bg-color;
    font-size: 50px;
    color: $dev-color;
    text-align: center;
    padding: 12px;
    margin: auto;
  }

  .centered-card {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
  }

  .separator {
    border-bottom: 1px solid $sep-line-color;
    width: 100%;
  }

  .cert-info {
    text-align: center;
    padding: 20px 15px 20px 15px;
    img {
      height: 60px;
    }
  }

  .cert-content {
    display: flex;
    flex-wrap: wrap;
  }

  .cert-logo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: $dev-bg-color;
    font-size: 30px;
    color: $dev-color;
    text-align: center;
    padding: 6px;
    margin: 0 auto;
  }

  .cert-name {
    color: $title-text-color;
    margin-top: 14px;
    font-weight: 600;
  }

  .cert-date {
    color: $body-text-color;
    font-weight: 400;
    font-size: 0.8rem;
  }

  .company-info {
    padding: 25px 10px 30px 10px;
    min-width: 200px;
    img {
      height: 90px;
    }

    .progress-bar {
      width: calc(max(200px, 60%));
      font-size: 0.8rem;
      color: $body-text-color;
      margin: 15px auto 0px;
    }

    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: $primary-color;
    }
  }

  .programme-name {
    color: $title-text-color;
    margin-top: 14px;
    font-weight: 600;
  }

  .file-name {
    cursor: pointer;
    color: $body-text-color;
  }
  .file-name:hover {
    color: $primary-color;
  }
}

.info-view {
  .content {
    padding: 15px 0px;
    margin-left: -8px;
  }

  .map-content {
    padding: 15px 0px;
  }
  .ant-steps-item-subtitle {
    display: block;
    margin-left: 0px;
    font-size: 0.8rem;
    color: $light-text-color;
    margin-top: -12px;
    font-weight: 500;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: $title-text-color;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-size: 0.8rem;
    font-weight: 500;
    color: $body-text-color;
  }

  .step-icon {
    border-radius: 100%;
    margin-right: 10px;
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
    font-size: 0.8rem;
  }

  .created-step {
    color: #ff8f56;
    background-color: rgba(#fef1ad, 0.5);
    font-size: 1rem;
    padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  }

  .auth-step {
    color: #00B6C2;
    background-color: rgba(#b9e2f4, 0.5);
  }
  
  .upcom-auth-step {
    color: #000000;
    background-color: rgba(#1e1f1f, 0.5);
  }

  .upcom-issue-step{
    color: #000000;
    background-color: rgba(#3A3541, 0.5);
  }

  .reject-step {
    color: #ff9900;
    background-color: rgba(#feb39f, 0.3);
  }

  .issue-step {
    color: #4f9d83;
    background-color: rgba(#80ff00, 0.12);
  }

  .cert-step {
    color: #4f9d83;
    background-color: rgba(#80ff00, 0.12);
  }

  .revoke-step {
    color: rgba(#ff00c7, 0.56);
    background-color: rgba(#ff9fde, 0.5);
  }

  .transfer-step {
    background-color: rgba(#d7dfe2, 0.5);
    color: #949599;
  }

  .retire-step {
    color: #ff4d4f;
    background-color: rgba(#ffd3d3, 0.5);
    padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  }

  .freeze-step {
    color: rgba(#976ed7, 1);
    background-color: rgba(#b7a4fe, 0.3);
  }

  .region-list {
    padding-top: 20px;
  }
}

.loc-tag {
  padding: 10px 20px 0px 0px;
  color: $body-text-color;
}

.loc-icon {
  margin-right: 5px;
  font-size: 1rem;
}

.loc-text {
  line-height: 1.2rem;
  vertical-align: top;
}

.apexcharts-datalabels-group {
  font-family: $primary-font-family !important;
  color: $title-text-color !important;
}

.remark {
  font-size: 0.8rem;
}

.footer-btn {
  margin: 39px 20px 0px 20px;
  display: flex;
  justify-content: center;
  column-gap: 30px;

  button {
    text-transform: uppercase;
  }
}

.retire-form {
  .ant-radio-group {
    text-align: left;
  }
}

.only-head {
  .title {
    margin-bottom: 0px !important;

    .link {
      font-size: 1.2rem;
      line-height: 0.5rem;
      cursor: pointer;
      color: $title-text-color;
    }
  }
}

.mapboxgl-popup-tip {
  border-bottom-color: $primary-color !important;
  border-top-color: $primary-color !important;
}

.mapboxgl-popup-content {
  background-color: $primary-color;
  color: white;
  font-family: $primary-font-family;
  font-weight: 500;
  min-width: 60px;
  height: 30px;
  padding: 5px 20px;
}
