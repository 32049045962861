@import "../../../Styles/variables.scss";

.filter-menu-item {
  .filter-title {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
}

.table-row-gray {
  background-color: #f0f0f0;
}

.user-data-download{
  margin-top: 0.8rem;
}

